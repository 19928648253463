export const passKey = "passname";

export const tipoCliente = [
    "HOME",
    "CORPORATIVO"
]

export const horasTrabajo = [
    "Normal"
]

export const semana = [
    "Semana",
    "Domingo/Feriado"
]

export const unidadMedida = [
    "Unitario",
    "Kilometros",
    "Metros"
]

export const tipoRemito = [
    "DEVOLUCION",
    "ENTREGA",
    "TRASPASO"
]

export const tipoDeco = [
    "ont",
    "flow",
    "ext"
]

export const tipoOt = [
    "Instalacion",
    "Soporte",
    "Mudanza externa",
    "Solo Visita",
    "No Factible"
]

export const ciudades = [
    "AREGUA",
    "ASUNCION",
    "CAPIATA",
    "FERNANDO DE LA MORA",
    "GUARAMBARE",
    "ITA",
    "ITAUGUA",
    "J. AUGUSTO SALDIVAR",
    "LAMBARE",
    "LIMPIO",
    "LUQUE",
    "MARIANO ROQUE ALONSO",
    "NUEVA ITALIA",
    "ÑEMBY",
    "SAN ANTONIO",
    "SAN LORENZO",
    "VILLA ELISA",
    "VILLETA",
    "YPACARI",
    "YPANE",
    "OTRO"
];

export const departamentos = [
    "ALTO PARAGUAY",
    "BOQUERÓN",
    "PRESIDENTE HAYES",
    "CONCEPCIÓN",
    "AMAMBAY",
    "SAN PEDRO",
    "CANINDEYÚ",
    "CORDILLERA",
    "CAAGUAZÚ",
    "ALTO PARANÁ",
    "CENTRAL",
    "PARAGUARÍ",
    "GUAIRÁ",
    "ÑEEMBUCÚ",
    "MISIONES",
    "CAAZAPÁ",
    "ITAPÚA",
]

export const idCuadrillaBodega = 0;
export const idCuadrillaNucleo = 1;
export const filesPage = 100;

export const headingsTable = [[
    "Item",
    "Nro Formulario",
    "Departamento",
    "CONTRATO",
    "OT",
    "Cliente",
    "CIUDAD",
    "Latitud",
    "Longitud",
    "Tipo de Cliente",
    "Hora de trabajo",
    "Dia",
    "Mes",
    "Año",
    "Fecha Asistida",
    "Dia",
    "Instalación Básica 1P (Solo FTTX)",
    "Instalación Sólo Flow",
    "Instalación Básica 2P (FTTX + FLOW)",
    "Media Jornada de trabajo - FTTH/Wireless",
    "Jornal completa de trabajo - FTTH/Wireless",
    "Soporte FTTX",
    "Soporte con Corte / Reinstalación FTTX",
    "Relevamiento (previo a instalación)",
    "Soporte en garantía",
    "Cable Preco 50 mts",
    "Cable Preco 100 mts",
    "Cable Preco 150 mts",
    "Cable Preco 220 mts",
    "Cable Preco 300 mts",
    "Cable Drop MTS. Externo",
    "Retenedor FO y Gancho",
    "Cinta Metalica",
    "Pitón 6mm",
    "Ojal de Acero",
    "Canaleta",
    "Cintillo",
    "TTP",
    "Conectores RJ45",
    "Cable UTP",
    "Conectores SC",
    "Hebillas",
    "Grampas",
    "Tarugo c/ Tope p/ tornillo Hueco 6mm",
    "Tornillo",
    "Nomenclatura del NAP",
    "Serial ONT",
    "Cantidad de Decos",
    "Serial Flow",
    "Serial Flow",
    "Serial Flow",
    "Serial Flow",
    "Serial Flow",
    "Serial Ext",
    "Serial Ext",
    "Serial Ext",
    "Serial Ext",
    "Serial Equipo Configurado",
    "Serial Equipo Configurado",
    "Serial Equipo Configurado",
    "Control Flow",
    "ONT Retirado",
    "Flow Retirado",
    "Ext Retirado",
    "ROSETA",
    "Numero de Cuadrilla",
    "Adaptador Mecánico",
    "Cuadrilla",
    "Usuario",
    "Instalación Básica 1P (Solo FTTX)",
    "Instalación Sólo Flow",
    "Instalación Básica 2P (FTTX + FLOW)",
    "Media Jornada de trabajo - FTTH/Wireless",
    "Jornal completa de trabajo - FTTH/Wireless",
    "Soporte FTTX",
    "Soporte con Corte / Reinstalación FTTX",
    "Relevamiento (previo a instalación)",
    "Soporte en garantía",
    "Total",
    "Comentarios",
    "Fecha realización"
]];

const cuadrilla = { "id": 4, "numero": "80CTD340 - ALVARO ROBLEDO" }

export const headingsTableGarantia = [[
    'FECHA 1RA OT',
    'CONTRATO 1RA OT',
    'NRO OT 1RA OT',
    'CLIENTE 1RA OT',
    'CUADRILLA 1RA OT',
    'COMENTARIOS 1RA OT',
    'FECHA 2DA OT',
    'CONTRATO 2DA OT',
    'NRO OT 2DA OT',
    'CLIENTE 2DA OT',
    'CUADRILLA 2DA OT',
    'COMENTARIOS 2DA OT',
    'MONTO'
]]